<template>
  <div class="manage-history">
    <header class="header">
      <el-button @click="$router.back()">返回</el-button>
      <aside>
        <!-- <el-button style="margin-right: 30px;" type="primary" @click="download" >下载</el-button>
        <div class="item">
          <span>下载时间：</span>
          <el-date-picker
            v-model="dateTime"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            placeholder="请选择时间"
          ></el-date-picker>
        </div> -->
        <el-select v-model="params.state" placeholder="请选择审核状态" @change="getList">
          <el-option label="通过" :value="2"></el-option>
          <el-option label="不通过" :value="3"></el-option>
        </el-select>
      </aside>
    </header>
    <DragTable :list-query="list" :header="header" :sort="false">
      <template #goods="{row}"><a class="word-color blue" :href="row.goods_url" target="_blank">{{row.goods_url}}</a></template>
      <template #state="{row}">{{row.state == 2 ? '通过' : row.state == 3 ? '不通过' : '待处理'}}</template>
    </DragTable>
    <footer class="table-footer">
      <p></p>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="params.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </footer>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from '@vue/composition-api'

export default defineComponent({
  setup(props,{root}) {
    const total = ref(10)
    const dateTime = ref('')
    const params = reactive({
      state: 2,
      page: 1,
      limit: 10,
      search_start_time: undefined,
      search_end_time: undefined
    })
    const header = [
      { name: "item_id", value: "item_id", width: 100 },
      { name: "审核时间", value: "operation" },
      { name: "站点来源", value: "site_name", },
      { name: "查看详情", slot: 'goods' },
      { name: "审核状态", value: "state", slot: 'state' },
    ];
    const list = reactive({ data: [] });
    watch(dateTime,val=>{
      if(val) {
        params.search_start_time = root.$dayjs(val[0]).unix()
        params.search_end_time = root.$dayjs(val[1]).unix()
      } else {
        params.search_start_time = undefined
        params.search_end_time = undefined
      }
      getList()
    })
    const getList = () => {
      root.$axios
        .get("/rcp/goodsManage/goodsImage/checkRecord", {
          params,
        })
        .then((res) => {
          list.data = res.data.list;
          total.value = res.data.total;
        });
    };
    getList()
    const download = () => {
      if(!dateTime.value) {
        root.$message.warning('请选择日期时间')
        return
      }
      root.$axios
        .get("/rcp/goodsManage/goodsImage/list/download", {
          params: {
            search_start_time: root.$dayjs(dateTime.value[0]).unix(),
            search_end_time: root.$dayjs(dateTime.value[1]).unix(),
            state: params.state
          }
        })
        .then((res) => {
          if(res.code === 10000) {
            const a = window.document.createElement('a')
            a.href= root.$store.state.CMS.url+res.data.file_path
            a.click()
          } else {
            root.$message.error(res.msg)
          }
        })
        
    }
    const handleSizeChange = (size) => {
      params.limit = size;
      params.page = 1
      getList();
    };
    const handleCurrentChange = (index) => {
      params.page = index;
      getList();
    };
    return {
      params,
      dateTime,
      header,
      list,
      total,
      getList,
      handleSizeChange,
      handleCurrentChange,
      download
    }
  },
})
</script>
<style lang="scss" scoped>
.header{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  aside{
    display: flex;
    align-items: center;
    &>div{
      margin-right: 10px;
    }
  }
}
.table-footer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>